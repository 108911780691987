const Noodl = require('@noodl/noodl-sdk')

const QueryDebug = () => {
    const [initialized, setInitialized] = useState(false)
    const [tryNum, setTryNum] = useState(0)

    useEffect(() => {
        setTimeout(() => {
            if (window.Rolder?.params.initialized) setInitialized(true)
            else setTryNum(tryNum + 1)
        }, 100)
    }, [tryNum])

    if (initialized && window.Rolder.params.debug > 0) {
        const QueryClientProvider = window.Rolder.libs.rq.QueryClientProvider
        const ReactQueryDevtools = window.Rolder.libs.rq.ReactQueryDevtools
        const queryClient = window.Rolder.libs.rq.client
        return (
            <QueryClientProvider client={queryClient}>
                <ReactQueryDevtools />
            </QueryClientProvider>
        )
    } else return null
}

const QueryDebugNode = Noodl.defineReactNode({
    name: 'QueryDebug v0.0.1',
    getReactComponent() {
        return QueryDebug;
    }
})

Noodl.defineModule({
    reactNodes: [QueryDebugNode],
    nodes: [],
    setup() { }
});